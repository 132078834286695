import Card0 from "../../../../content/posts/2021-05-28-framer-motion/Card0.tsx";
import Card1 from "../../../../content/posts/2021-05-28-framer-motion/Card1.tsx";
import Card2 from "../../../../content/posts/2021-05-28-framer-motion/Card2.tsx";
import Card3 from "../../../../content/posts/2021-05-28-framer-motion/Card3.tsx";
import Card4 from "../../../../content/posts/2021-05-28-framer-motion/Card4.tsx";
import * as React from 'react';
export default {
  Card0,
  Card1,
  Card2,
  Card3,
  Card4,
  React
};